import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, sendVerificationEmail } from "../../../api/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./EmailVerification.scss";
import { storeContext } from "../../../store";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchUserInfo } from "../../../actions";

export default function EmailVerification() {
    const [user, loading, error] = useAuthState(auth);
    const { fromLogin, setFromLogin, region } = useContext(storeContext);
    const [seconds, setSeconds] = useState(60);
    const [isSending, setIsSending] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        if (user && user?.emailVerified) navigate("/dashboard");
        if (error) navigate("/");
    }, [user, loading, error]);

    useEffect(() => {
        if (fromLogin) {
            setSeconds(0);
            setFromLogin(false);
            return;
        } else if (seconds > 0) {
            const timer = setTimeout(
                () => setSeconds((prevSeconds) => prevSeconds - 1),
                1000
            );
            return () => clearTimeout(timer);
        }
    }, [fromLogin, seconds, setFromLogin]);

    const getUserName = async () => {
        try {
            const userInfo = await fetchUserInfo(user, region);
            return `${userInfo.firstName} ${userInfo.lastName}`;
        } catch (err) {
            console.error(err);
            return "Preteeth AI User";
        }
    };

    const showErrorMessage = (text) => {
        message.error({
            content: text,
            className: "error-message",
            duration: 5,
        });
    };

    async function resendEmail() {
        setIsSending(true);
        try {
            const name = await getUserName();
            await sendVerificationEmail(auth.currentUser.email, name);
            setIsSending(false);
            setIsSend(true);
            setSeconds(60);
        } catch (error) {
            showErrorMessage(
                "Too many attempts, please try again later. If you still can't receive the email, please contact us."
            );
            setIsSending(false);
        }
    }

    const checkEmailVerified = async () => {
        await user.reload();
        if (user.emailVerified) navigate("/dashboard");
        else
            showErrorMessage(
                "Your email seems not verified yet, please check your email inbox."
            );
    };

    const handleSignInWithAnotherAccount = async () => {
        try {
            await auth.signOut();
            navigate("/login");
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="emailVerification">
            <Link to="/">
                <img
                    src={require("../../../assets/img/logo/logo_dentscape.webp")}
                    alt="Dentscape"
                    className="login-logo"
                />
            </Link>
            <div className="emailVerification-container">
                <h3 className="title">
                    {isSend
                        ? "Verification email sent successfully"
                        : "Verify your email address"}
                </h3>
                <p className="text">
                    {isSend ? (
                        "Please check your email inbox, It might takes up to 10 minutes to receive the verification email, and the email could be in the spam folder."
                    ) : (
                        <>
                            We sent a verification email to{" "}
                            <span>{user?.email}</span>
                            <br></br>
                            Please check your email and click on the
                            verification link to continue.
                        </>
                    )}
                </p>

                {seconds > 0 ? (
                    <p style={{ color: "#FFFFFF" }}>
                        Haven't receive the email ? You can resend after {"("}
                        {seconds}
                        {")"}
                    </p>
                ) : (
                    <button
                        className="btn-emailVerification"
                        onClick={resendEmail}
                    >
                        {isSending ? <LoadingOutlined /> : "Resend"}
                    </button>
                )}

                <button
                    className="btn-isEmailVerified"
                    onClick={checkEmailVerified}
                >
                    Verification completed, continue
                </button>
            </div>
            <button
                className="btn-signInWithAnotherAccount"
                onClick={handleSignInWithAnotherAccount}
            >
                Sign in with another account
            </button>
        </div>
    );
}
