import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import "./Delete.scss";
import { auth, db, deleteAccount } from "../../../api/firebase";
import { doc, getDoc } from "firebase/firestore";
import { Modal, Input, Radio, Skeleton, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function Delete() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [pageLoading, setPageLoading] = useState(true);
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [reason, setReason] = useState("");
    const [isReason, setIsReason] = useState();
    const [isOther, setIsOther] = useState(false);
    const [handleEnterPassword, setHandleEnterPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [deleting, setDeleting] = useState(false);
    const { confirm } = Modal;

    let didInit = false;
    useEffect(() => {
        if (!didInit) {
            didInit = true;
            const fetchUserName = async () => {
                try {
                    const userRef = doc(db, "user_datas", user?.uid);
                    const userDoc = await getDoc(userRef);
                    const data = userDoc.data();
                    setName(data.name);
                    setFirstName(data.firstName);
                    setLastName(data.lastName);
                } catch (err) {
                    console.error(err);
                    setName("Preteeth AI user");
                }
            };
            if (!loading) setPageLoading(false);
            if (!user) navigate("/login?redirectTo=delete");
            if (user && !user?.emailVerified) navigate("/emailVerification");
            if (user && user?.emailVerified) fetchUserName();
            if (error) navigate("/");
        }
    }, [user, loading, error, navigate]);

    const showErrorMessage = (text) => {
        message.error({
            content: text,
            className: "error-message",
            duration: 5,
        });
    };

    const onChange = (e) => {
        setReason(e.target.value);
        setIsReason(true);
        setIsOther(false);
    };

    const warning = () => {
        confirm({
            title: "Delete account",
            icon: <img src={require("../../../assets/img/logo/圖藍.png")} alt="logo" className="logo" loading="lazy" />,
            className: "delete-modal",
            content: "Are you sure you want to delete your Preteeth AI account?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            centered: true,
            onOk() {
                if (user?.providerData[0]?.providerId === "google.com") deleteUserAccount();
                else setHandleEnterPassword(true);
            },
        });
    };

    const onClick = () => {
        if (reason === "") {
            setIsReason(false);
        } else {
            warning();
        }
    };

    const deleteUserAccount = async () => {
        setDeleting(true);
        const deleteStatus = await deleteAccount({
            deleteReason: reason,
            oldPassword: password,
        });
        setDeleting(false);
        if (deleteStatus.status === true) navigate("/accountDeleted");
        else if (deleteStatus.status === false) showErrorMessage(deleteStatus.message);
    };

    return (
        <div className="delete">
            {pageLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Link to="/">
                        <img
                            src={require("../../../assets/img/logo/logo_dentscape.webp")}
                            alt="Dentscape"
                            className="login-logo"
                            loading="lazy"
                        />
                    </Link>
                    <div className="delete-container">
                        {handleEnterPassword ? (
                            <>
                                <p className="delete-title">Please enter your password to confirm.</p>
                                <Input.Password
                                    className="input-textBox"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password"
                                    iconRender={(visible) =>
                                        visible ? (
                                            <img src={require("../../../assets/img/icon_show.png")} />
                                        ) : (
                                            <img src={require("../../../assets/img/icon_hide.png")} />
                                        )
                                    }
                                    onPressEnter={deleteUserAccount}
                                />

                                <button className="btn-delete" onClick={deleteUserAccount}>
                                    {deleting ? <LoadingOutlined /> : "Delete account"}
                                </button>
                            </>
                        ) : (
                            <>
                                {name ? (
                                    <h4 className="delete-title">
                                        <span className="delete-title-name">{name}</span> , <br></br>
                                        we're sorry to see you go.
                                    </h4>
                                ) : (
                                    <h4 className="delete-title">
                                        <span className="delete-title-name">
                                            {firstName} {lastName}
                                        </span>{" "}
                                        , <br></br>we're sorry to see you go.
                                    </h4>
                                )}
                                <p>We would like to konw why you want to delete your account?</p>
                                {isReason === false && <p className="warningText">*Please choose an option.</p>}
                                <Radio.Group
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        marginTop: "5px",
                                    }}
                                >
                                    <Radio value="I'm switching to someone else" onChange={onChange}>
                                        <p className="radio-text">I'm switching to someone else</p>
                                    </Radio>
                                    <Radio value="It's too expensive" onChange={onChange}>
                                        <p className="radio-text">It's too expensive</p>
                                    </Radio>
                                    <Radio value="It's not helpful for my consultation" onChange={onChange}>
                                        <p className="radio-text">It's not helpful to me</p>
                                    </Radio>
                                    {isOther ? (
                                        <div className="otherContainer">
                                            <Radio value="Other" onClick={() => setIsOther(true)}>
                                                <p className="radio-text">Other</p>
                                            </Radio>
                                            <Input
                                                className="otherInput"
                                                placeholder="Tell us your reason"
                                                onChange={(e) => setReason(e.target.value)}
                                            />
                                        </div>
                                    ) : (
                                        <Radio value="Other" onClick={() => setIsOther(true)}>
                                            <p className="radio-text">Other</p>
                                        </Radio>
                                    )}
                                </Radio.Group>

                                <button className="btn-delete" onClick={onClick}>
                                    {deleting ? <LoadingOutlined /> : "Delete account"}
                                </button>

                                <button className="btn-logout" onClick={() => auth.signOut()}>
                                    or <span>Logout</span>
                                </button>
                            </>
                        )}
                    </div>

                    {/* <Link className="btn-toDashboard" to="/dashboard">
                        Back to dashboard
                    </Link> */}
                </>
            )}
        </div>
    );
}
