import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import "./Dashboard.scss";
import { auth, checkFreeTrial, logout } from "../../../api/firebase";
import { storeContext } from "../../../store";
import {
    createFreeStripeSubscription,
    createStripeCustomer,
    fetchStripeCustomer,
} from "../../../api/payment";
import { Col, Row, Skeleton } from "antd";
import { Tabs } from "antd";
import AccountSetting from "./AccountSetting";
import Billing from "./Billing";
import { fetchUserInfo, useQuery } from "../../../actions";
import { addPerson, searchPeople, updatePerson } from "../../../api/pipedrive";
import Onboarding from "../Onboarding";

export default function Dashboard() {
    const {
        name,
        setName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        setType,
        setCountry,
        setCity,
        setPhone,
        region,
        setPreteethAIProPayment,
        setIsFreeTrial,
        // setDentalAssistantPayment,
    } = useContext(storeContext);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [openOnboarding, setOpenOnboarding] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [paymentLoading, setPaymentLoading] = useState(true);

    const q = useQuery();
    const success = q.get("success");

    const getUserInfo = async () => {
        try {
            const userInfo = await fetchUserInfo(user, region);
            const isFreeTrial =
                userInfo.freeTrialQuota - userInfo.freeTrialUsage > 0 ||
                userInfo.freeTrialQuota === undefined;
            if (
                userInfo.type === undefined &&
                userInfo.country === undefined &&
                userInfo.city === undefined &&
                userInfo.phone === undefined &&
                userInfo.hear === undefined
            ) {
                setOpenOnboarding(true);
                setName(userInfo.name);
                setFirstName(userInfo.firstName);
                setLastName(userInfo.lastName);
                setDataLoading(userInfo.dataLoading);
            } else {
                setName(userInfo.name);
                setFirstName(userInfo.firstName);
                setLastName(userInfo.lastName);
                setType(userInfo.type);
                setCountry(userInfo.country);
                setCity(userInfo.city);
                setPhone(userInfo.phone);
                setIsFreeTrial(isFreeTrial);
                setDataLoading(userInfo.dataLoading);
            }
            return {
                name: userInfo.name,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                type: userInfo.type,
                country: userInfo.country,
                city: userInfo.city,
                phone: userInfo.phone,
                hear: userInfo.hear,
                registerDate: userInfo.registerDate,
                uid: user.uid,
            };
        } catch (err) {
            console.error(err);
            return {
                name: "Preteeth AI User",
            };
        }
    };

    const fetchPaymentInfo = async () => {
        const stripeCustomer = await fetchStripeCustomer(user?.uid);
        if (
            stripeCustomer.message === "No such customer" ||
            (stripeCustomer.error &&
                stripeCustomer.error.includes("No such customer"))
        )
            await createCustomer();
        else {
            const preteethAIProPayments = stripeCustomer.message.filter(
                (item) => item.product === "Preteeth AI Pro"
            );

            // const dentalAssistantPayments = stripeCustomer.message.reduce(
            //   (acc, item) => {
            //     if (item.product === "Dental Assistant") {
            //       acc.push(item);
            //     }
            //     return acc;
            //   },
            //   []
            // );

            setPreteethAIProPayment(preteethAIProPayments);
            // setDentalAssistantPayment(dentalAssistantPayments);
        }

        setPaymentLoading(false);
    };

    const updatePipedrivePerson = async () => {
        if (!user) return;
        const { totalItems, id } = await searchPeople(user?.email);
        const userInfo = await fetchUserInfo(user, region);

        if (totalItems >= 1) {
            await updatePerson({
                id,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                type: userInfo.type,
                email: user?.email,
                country: userInfo.country,
                city: userInfo.city,
                phoneNumber: userInfo.phone,
                hear: userInfo.hear,
                registerDate: userInfo.registerDate,
                uid: user?.uid,
            });
        } else {
            await addPerson({
                email: user?.email,
                status: "Verified",
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                type: userInfo.type,
                country: userInfo.country,
                city: userInfo.city,
                phoneNumber: userInfo.phone,
                hear: userInfo.hear,
                registerDate: userInfo.registerDate,
                uid: user?.uid,
            });
        }
    };

    const createCustomer = async () => {
        const { name, firstName, lastName } = await getUserInfo();
        const customerName =
            name !== undefined ? name : `${firstName} ${lastName}`;
        await createStripeCustomer(
            user?.email,
            user?.uid,
            customerName,
            fetchPaymentInfo
        );
    };

    let didInit = false;
    useEffect(() => {
        const fetchData = async () => {
            if (!user) return;
            const { name, firstName, lastName } = await getUserInfo();
            if (name !== "" || firstName !== "" || lastName !== "")
                fetchPaymentInfo();
        };

        if (!didInit) {
            didInit = true;
            if (loading) return;
            if (!user)
                navigate(`/login?redirectTo=dashboard?success=${success}`);
            if (user && !user?.emailVerified) navigate("/emailVerification");
            if ((user && user?.emailVerified) || success === "true") {
                fetchData();
                if (user?.providerData[0]?.providerId === "google.com") {
                    setTimeout(() => {
                        updatePipedrivePerson();
                    }, 3000);
                } else {
                    updatePipedrivePerson();
                }
            }
            if (error) navigate("/");
        }
    }, [user, loading, error]);

    function handleLogout() {
        logout();
        navigate("/");
    }

    const items = [
        {
            key: "1",
            label: `Account Setting`,
            children: dataLoading ? <Skeleton active /> : <AccountSetting />,
        },
        {
            key: "2",
            label: `Billing`,
            children:
                dataLoading || paymentLoading ? (
                    <Skeleton active />
                ) : (
                    <Billing />
                ),
        },
    ];

    const reLoadUserInfo = async () => {
        await getUserInfo();
        const isFreeTrial = await checkFreeTrial(user?.email);
        if (isFreeTrial) {
            // create free trial subscription
            await createFreeStripeSubscription({
                uid: user?.uid,
                priceId: process.env.REACT_APP_PRICE_ID_MEDIUM_MONTHLY,
            });
        }
        setOpenOnboarding(false);
    };

    return (
        <div className="dashboard">
            {openOnboarding && <Onboarding callback={reLoadUserInfo} />}

            <Link to="/">
                <img
                    src={require("../../../assets/img/logo/logo_dentscape.webp")}
                    alt="Dentscape"
                    className="logo"
                    loading="lazy"
                    style={{
                        filter: openOnboarding
                            ? "blur(3px) brightness(0.7)"
                            : "none",
                    }}
                />
            </Link>

            <Row
                className="dashboard-content"
                style={{
                    filter: openOnboarding
                        ? "blur(3px) brightness(0.7)"
                        : "none",
                }}
            >
                <Col className="info-box" xl={6}>
                    <div>
                        <img
                            src={require("../../../assets/img/dentist/img_avatar.png")}
                            className="avatar"
                        />
                        {name ? (
                            <h4 className="userName">{name}</h4>
                        ) : (
                            <h4 className="userName">
                                {firstName} {lastName}
                            </h4>
                        )}
                    </div>

                    <div>
                        <button className="btn-logout" onClick={handleLogout}>
                            Logout
                            <img
                                src={require("../../../assets/img/dentist/icon_logout.png")}
                            />
                        </button>
                    </div>
                </Col>

                <Col className="content-box" xl={17}>
                    <Tabs
                        defaultActiveKey={success === "true" ? "2" : "1"}
                        items={items}
                        size="middle"
                    />
                </Col>
            </Row>
        </div>
    );
}
