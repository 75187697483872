import { Divider } from "antd";
import "./SubscriptionPlans.scss";

export default function SubscriptionPlans({ onClickGetStarted }) {
    const basicPlanFeatures = ["AI-Powered Facial & Dental Analysis", "AI DSD", "Compare different treatment plan"];

    const premiunPlanFeatures = [
        "AI-Powered Facial & Dental Analysis",
        "AI DSD  (CLP, Veneer, and Bleaching simulations.)",
        "Compare different treatment plan",
    ];

    return (
        <div className="subscription-plans">
            <div className="plan-card">
                <h2 className="plan-title">Basic</h2>
                <p className="plan-description">
                    Basic users are granted <span style={{ fontWeight: "bold" }}>3 complimentary cases</span> to
                    experience our full Premium features.
                </p>
                <Divider className="plan-divider" />
                <div className="plan-features">
                    {basicPlanFeatures.map((feature, index) => (
                        <div className="feature-text" key={index}>
                            <XIcon />
                            <p style={{ color: "#9D9D9D" }}>{feature}</p>
                        </div>
                    ))}
                </div>
                <button className="btn-current-plan" disabled>
                    Current Plan
                </button>
            </div>

            <div className="plan-card">
                <h2 className="plan-title premium-plan-title">Premium</h2>
                <p className="plan-description">
                    Premium users can enjoy <span style={{ fontWeight: "bold" }}>unlimited usage of all features</span>.
                </p>
                <Divider className="plan-divider" />
                <div className="plan-features">
                    {premiunPlanFeatures.map((feature, index) => (
                        <div className="feature-text" key={index}>
                            <CheckIcon />
                            <p>{feature}</p>
                        </div>
                    ))}
                </div>
                <button className="btn-premium-get-started" onClick={onClickGetStarted}>
                    Get Started Now
                </button>
            </div>
        </div>
    );
}

const XIcon = () => (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_5535_8174"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="22"
            height="22"
        >
            <rect width="22" height="22" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5535_8174)">
            <path
                d="M10.999 12.0059L13.8895 14.8964C14.0343 15.0412 14.2021 15.1136 14.3928 15.1136C14.5834 15.1136 14.7511 15.0403 14.8958 14.8936C15.0443 14.747 15.1185 14.5788 15.1185 14.3892C15.1185 14.1995 15.0443 14.0313 14.8958 13.8846L12.0053 10.9996L14.8958 8.10916C15.0406 7.96432 15.1139 7.79657 15.1158 7.60591C15.1176 7.41524 15.0443 7.24757 14.8958 7.10289C14.7496 6.95439 14.5819 6.88014 14.3928 6.88014C14.2036 6.88014 14.0359 6.95439 13.8895 7.10289L10.999 9.99337L8.10854 7.10289C7.96371 6.95805 7.79688 6.88472 7.60804 6.88289C7.41921 6.88105 7.25245 6.95439 7.10777 7.10289C6.95927 7.2491 6.88502 7.41677 6.88502 7.60591C6.88502 7.79505 6.95927 7.9628 7.10777 8.10916L9.99275 10.9996L7.10227 13.8901C6.95744 14.035 6.88502 14.2018 6.88502 14.3906C6.88502 14.5794 6.95835 14.7462 7.10502 14.8909C7.25169 15.0394 7.41982 15.1136 7.60942 15.1136C7.79917 15.1136 7.96738 15.0394 8.11404 14.8909L10.999 12.0059ZM10.9997 20.3033C9.72463 20.3033 8.52119 20.06 7.38942 19.5732C6.25764 19.0865 5.26909 18.4204 4.42377 17.5749C3.5783 16.7296 2.91219 15.7413 2.42544 14.6099C1.93869 13.4788 1.69531 12.2756 1.69531 11.0003C1.69531 9.70996 1.93869 8.49889 2.42544 7.36712C2.91219 6.23534 3.57799 5.25046 4.42285 4.41247C5.26772 3.57448 6.25588 2.91135 7.38735 2.42307C8.51883 1.93464 9.72233 1.69043 10.9979 1.69043C12.2885 1.69043 13.5 1.93449 14.6322 2.42262C15.7643 2.91074 16.7493 3.57349 17.5871 4.41087C18.4248 5.24839 19.0877 6.23312 19.5758 7.36505C20.0641 8.49683 20.3082 9.70836 20.3082 10.9996C20.3082 12.2753 20.064 13.479 19.5756 14.6106C19.0873 15.7424 18.4242 16.7307 17.5862 17.5756C16.7482 18.4206 15.7635 19.0865 14.6322 19.5732C13.5011 20.06 12.2902 20.3033 10.9997 20.3033Z"
                fill="#9D9D9D"
            />
        </g>
    </svg>
);

const CheckIcon = () => (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_5535_8198"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="22"
            height="22"
        >
            <rect width="22" height="22" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5535_8198)">
            <path
                d="M9.63594 12.9425L7.49438 10.801C7.34221 10.6488 7.15567 10.5727 6.93475 10.5727C6.71383 10.5727 6.5182 10.6564 6.34785 10.8239C6.19584 10.9795 6.11983 11.1677 6.11983 11.3883C6.11983 11.6088 6.19767 11.7951 6.35335 11.9472L9.08342 14.7002C9.24093 14.8559 9.42465 14.9337 9.63456 14.9337C9.84448 14.9337 10.0291 14.8559 10.1885 14.7002L15.5998 9.28341C15.7709 9.11596 15.8564 8.9227 15.8564 8.70362C15.8564 8.48453 15.7709 8.28989 15.5998 8.1197C15.4323 7.96753 15.2343 7.89527 15.0058 7.90291C14.7772 7.91055 14.5855 7.99045 14.4306 8.14262L9.63594 12.9425ZM10.9997 20.3033C9.72463 20.3033 8.52119 20.0598 7.38942 19.5728C6.25779 19.0857 5.2694 18.4196 4.42423 17.5744C3.57906 16.7293 2.91295 15.7411 2.4259 14.6099C1.93884 13.4788 1.69531 12.2756 1.69531 11.0003C1.69531 9.70996 1.93884 8.49889 2.4259 7.36712C2.91295 6.23549 3.57876 5.25084 4.42331 4.41316C5.26787 3.57533 6.25588 2.91212 7.38735 2.42353C8.51883 1.9348 9.72233 1.69043 10.9979 1.69043C12.2885 1.69043 13.5 1.93464 14.6322 2.42307C15.7643 2.91151 16.749 3.57433 17.5864 4.41155C18.4239 5.24878 19.0869 6.23328 19.5754 7.36505C20.0639 8.49683 20.3082 9.70836 20.3082 10.9996C20.3082 12.2753 20.0639 13.479 19.5751 14.6106C19.0865 15.7422 18.4233 16.7304 17.5855 17.5751C16.7478 18.4198 15.7634 19.0857 14.6322 19.5728C13.5011 20.0598 12.2902 20.3033 10.9997 20.3033Z"
                fill="url(#paint0_linear_5535_8198)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_5535_8198"
                x1="11.0018"
                y1="1.69043"
                x2="11.0018"
                y2="20.3033"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#88EDF1" />
                <stop offset="1" stopColor="#4393A2" />
            </linearGradient>
        </defs>
    </svg>
);
