import { useEffect, useState } from "react";
import { Button, Input, Modal, Select, message } from "antd";
import "./Newsletter.scss";
import { addPerson, searchPeople, updatePerson } from "../../../../api/pipedrive";
import { LoadingOutlined } from "@ant-design/icons";

export default function Newsletter() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
    });
    const [btnStatus, setBtnStatus] = useState(false);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isValidEmail = (address) => {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(address);
    };

    const handleEmailVerify = (e) => {
        if (!isValidEmail(e.target.value)) {
            setError("Invalid Email");
        } else {
            setError(null);
        }
    };

    const showErrorMessage = (text) => {
        message.error({
            content: text,
            className: "error-message",
            duration: 5,
        });
    };

    const handleSubmit = async (e) => {
        setIsSubmitting(true);

        try {
            // Search if the person already exists
            const { id } = await searchPeople(formData.email);

            if (id) {
                await updatePerson({
                    id,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    jobTitle: formData.jobTitle,
                    newsletterSubscription: true,
                    marketingMail: true,
                    marketingStatus: "subscribed",
                });
            } else {
                // Add a new person
                await addPerson({
                    email: formData.email,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    jobTitle: formData.jobTitle,
                    newsletterSubscription: true,
                    marketingMail: true,
                });
            }

            setFormData({
                firstName: "",
                lastName: "",
                jobTitle: "",
                email: "",
            });

            openSuccessModal();
        } catch (error) {
            console.error("Error in handleSubmit", error);
            showErrorMessage("Error in submit");
        } finally {
            setIsSubmitting(false);
        }
    };

    const openSuccessModal = () => {
        Modal.success({
            className: "success-modal",
            width: "fit-content",
            content: (
                <p>
                    Thank you for subscribing!
                    <br />
                    We will send our latest tips, updates, and insights directly in your inbox.
                </p>
            ),
            centered: true,
        });
    };

    useEffect(() => {
        setBtnStatus((prev) => Object.values(formData).every((field) => field.trim() !== "") && !error);
    }, [formData]);

    return (
        <div className="newsletter">
            <h1 className="newsletter-title">Subscribe to the Dentscape newsletter</h1>
            <p className="newsletter-subtitle">
                Join our newsletter to receive the latest tips, updates, and insights directly in your inbox.
            </p>

            <div className="message-container">
                <div style={{ width: "100%" }}>
                    <Input.Group>
                        <div className="row flex-column flex-xl-row">
                            <div className="col">
                                <div className="input-item">
                                    <Input
                                        name="firstName"
                                        placeholder="First Name*"
                                        type="text"
                                        value={formData.firstName}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                firstName: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="input-item">
                                    <Input
                                        name="lastName"
                                        placeholder="Last Name*"
                                        value={formData.lastName}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                lastName: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-item">
                            <Select
                                placeholder="Select your job title*"
                                value={formData.jobTitle !== "" ? formData.jobTitle : undefined}
                                onChange={(value) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        jobTitle: value,
                                    }))
                                }
                                style={{ color: formData.jobTitle ? "white" : "gray" }}
                            >
                                <Select.Option value="Dental Technician">Dental Technician</Select.Option>
                                <Select.Option value="Dental Lab Owner">Dental Lab Owner</Select.Option>
                                <Select.Option value="Dentist">Dentist</Select.Option>
                                <Select.Option value="Employee in Dental Lab">Employee in Dental Lab</Select.Option>
                                <Select.Option value="Employee in Dental Clinic">
                                    Employee in Dental Clinic
                                </Select.Option>
                                <Select.Option value="Personnel in Dental Company">
                                    Personnel in Dental Company
                                </Select.Option>
                                <Select.Option value="Student">Student</Select.Option>
                                <Select.Option value="Others">Others</Select.Option>
                            </Select>
                        </div>

                        <div className="input-item">
                            <Input
                                placeholder="Enter your email*"
                                type="email"
                                value={formData.email}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        email: e.target.value,
                                    }))
                                }
                                onChangeCapture={handleEmailVerify}
                            />
                        </div>
                        {error && (
                            <p
                                style={{
                                    color: "#EE7963",
                                    fontSize: "1rem",
                                    marginTop: "-10px",
                                }}
                            >
                                {error}
                            </p>
                        )}
                    </Input.Group>
                    <Button className="btn-send" disabled={!btnStatus || isSubmitting} onClick={handleSubmit}>
                        {isSubmitting ? <LoadingOutlined /> : "Subscribe"}
                    </Button>
                </div>
            </div>
        </div>
    );
}
