import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { auth, changePassword, logout } from "../../../api/firebase";
import "./ChangePassword.scss";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function ChangePassword() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passcheck, setPasscheck] = useState("");
  const [lengthcheck, setLengthcheck] = useState();
  const [isOldPassword, setIsOldPassword] = useState(false);
  const [same, setSame] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/login");
    if (user && user?.emailVerified) navigate("/changePassword");
    if (user && !user?.emailVerified) navigate("/emailVerification");
    if (error) navigate("/");
  }, [user, loading, error, navigate]);

  useEffect(() => {
    if (oldPassword) setIsOldPassword(true);

    if (newPassword.length < 6) setLengthcheck(false);
    else setLengthcheck(true);

    if (lengthcheck && passcheck && passcheck === newPassword) setSame(true);
    else setSame(false);
  }, [oldPassword, newPassword, passcheck]);

  const send = async () => {
    setIsChanging(true);
    const result = await changePassword({ oldPassword, newPassword });
    setIsChanging(false);
    setChangeStatus(result);
  };

  return (
    <div className="change-password">
      <Link to="/">
        <img
          src={require("../../../assets/img/logo/logo_dentscape.webp")}
          alt="Dentscape"
          className="login-logo"
          loading="lazy"
        />
      </Link>

      <div className="change-container">
        {changeStatus.status ? (
          <>
            <h3 className="verified-title">Success!</h3>
            <p className="verified-text">
              Your password has been changed, <br></br>please log in again.
            </p>
            <button
              className="btn-loginAgain"
              style={{ marginTop: "20px", transition: "0.5s" }}
              onClick={logout}
            >
              Log in again
            </button>
          </>
        ) : (
          <>
            <Input.Password
              className="input-textBox"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder="Old password*"
              iconRender={(visible) =>
                visible ? (
                  <img src={require("../../../assets/img/icon_show.png")} />
                ) : (
                  <img src={require("../../../assets/img/icon_hide.png")} />
                )
              }
            />

            <Input.Password
              className="input-textBox"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New password*"
              iconRender={(visible) =>
                visible ? (
                  <img src={require("../../../assets/img/icon_show.png")} />
                ) : (
                  <img src={require("../../../assets/img/icon_hide.png")} />
                )
              }
            />

            <Input.Password
              className="input-textBox"
              value={passcheck}
              onChange={(e) => setPasscheck(e.target.value)}
              placeholder="Confirm password*"
              iconRender={(visible) =>
                visible ? (
                  <img src={require("../../../assets/img/icon_show.png")} />
                ) : (
                  <img src={require("../../../assets/img/icon_hide.png")} />
                )
              }
            />
            {newPassword && lengthcheck === false ? (
              <p style={{ color: "#aa3e1c", fontSize: "0.9rem" }}>
                Minimum 6 characters
              </p>
            ) : (
              <></>
            )}
            {isOldPassword && same && lengthcheck === true ? (
              <CheckCircleOutlineIcon
                style={{
                  color: "#30BFA0",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {changeStatus.status === false && (
              <p style={{ color: "#aa3e1c", fontSize: "0.9rem" }}>
                {changeStatus.message}
              </p>
            )}

            <button
              className="btn-reset"
              onClick={send}
              disabled={same && isOldPassword ? false : true}
            >
              {isChanging ? <LoadingOutlined /> : "Reset password"}
            </button>

            <Link className="btn-toDashboard" to="/dashboard">
              Back to dashboard
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
export default ChangePassword;
