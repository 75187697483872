import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    auth,
    logInWithEmailAndPassword,
    signUpWithGoogle,
} from "../../../api/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./style.scss";
import { useQuery } from "../../../actions";
import { storeContext } from "../../../store";
import { Input, message } from "antd";

export default function Login() {
    const { setFromLogin } = useContext(storeContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [incorrect, setIncorrect] = useState(null);
    const navigate = useNavigate();

    const queryParams = useQuery();
    const redirectLink = queryParams.get("redirectTo");

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (user && user?.emailVerified) {
            if (redirectLink === null) navigate("/dashboard");
            else navigate(`/${redirectLink}`);
        }
        if (user && !user?.emailVerified) {
            if (redirectLink === null) navigate("/emailVerification");
            else navigate(`/${redirectLink}`);
            setFromLogin(true);
        }
        if (error) navigate("/");
    }, [user, loading, error, navigate]);

    const showErrorMessage = (text) => {
        setIncorrect(text);

        let errorMessage;
        if (email === "") errorMessage = "Please enter your email.";
        else if (text === "Firebase: Error (auth/missing-password).")
            errorMessage = "Please enter your password.";
        else if (text === "Firebase: Error (auth/wrong-password).")
            errorMessage = "Incorrect password, please try again.";
        else if (text === "Firebase: Error (auth/invalid-email).")
            errorMessage = "Please enter a valid email address.";
        else if (text === "Firebase: Error (auth/user-not-found).")
            errorMessage =
                "Account is not found. Please make sure your email is correct or sign up.";
        else errorMessage = text;

        message.error({
            content: errorMessage,
            className: "error-message",
            duration: 5,
        });
    };

    return (
        <div className="login">
            <Link to="/">
                <img
                    src={require("../../../assets/img/logo/logo_dentscape.webp")}
                    alt="Dentscape"
                    className="login-logo"
                />
            </Link>

            <div className="login-container">
                <h3>Sign in</h3>

                <button
                    className="btn-google"
                    onClick={() => signUpWithGoogle()}
                >
                    <img
                        src={require("../../../assets/img/dentist/icon_google_account.webp")}
                        alt="google"
                        className="icon-google"
                    />
                    Continue with Google
                </button>

                <div className="divider">
                    <div className="divider-line" />
                    <div className="divider-text">or</div>
                    <div className="divider-line" />
                </div>

                <div className="input-box">
                    <p
                        style={{
                            color:
                                incorrect ===
                                    "Firebase: Error (auth/invalid-email)." ||
                                incorrect ===
                                    "Firebase: Error (auth/user-not-found)."
                                    ? "#aa3e1c"
                                    : "",
                        }}
                    >
                        Email
                    </p>
                    <Input
                        type="text"
                        className="input-textBox"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setIncorrect(null);
                        }}
                        style={{
                            borderColor:
                                incorrect ===
                                    "Firebase: Error (auth/invalid-email)." ||
                                incorrect ===
                                    "Firebase: Error (auth/user-not-found)."
                                    ? "#aa3e1c"
                                    : "",
                        }}
                    />
                </div>

                <div className="input-box">
                    <p
                        style={{
                            color:
                                incorrect ===
                                    "Firebase: Error (auth/missing-password)." ||
                                incorrect ===
                                    "Firebase: Error (auth/wrong-password)."
                                    ? "#aa3e1c"
                                    : "",
                        }}
                    >
                        Password
                    </p>
                    <Input.Password
                        className="input-textBox"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setIncorrect(null);
                        }}
                        iconRender={(visible) =>
                            visible ? (
                                <img
                                    src={require("../../../assets/img/icon_show.png")}
                                />
                            ) : (
                                <img
                                    src={require("../../../assets/img/icon_hide.png")}
                                />
                            )
                        }
                        style={{
                            borderColor:
                                incorrect ===
                                    "Firebase: Error (auth/missing-password)." ||
                                incorrect ===
                                    "Firebase: Error (auth/wrong-password)."
                                    ? "#aa3e1c"
                                    : "",
                        }}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                    }}
                >
                    <Link className="btn-forgot" to="/sendreset">
                        Forgot Password
                    </Link>
                </div>

                <button
                    className="btn-login"
                    onClick={() =>
                        logInWithEmailAndPassword(
                            email,
                            password,
                            showErrorMessage
                        )
                    }
                >
                    Sign in
                </button>
            </div>

            <p className="signUp-text">
                Don't have an account? &nbsp; &nbsp;
                <Link className="btn-signUp" to="/register">
                    Sign up
                </Link>
            </p>
        </div>
    );
}
